<template>
  <div>
    <div class="recruit-hero">
      <b-container>
        <div>
          <b-img
            src="../assets/images/recruit_1.png"
            alt="ด่วน! รับสมัครแม่บ้าน หลายอัตรา"
            class="d-block mx-auto recruit-title"
          ></b-img>
          <div class="mt-4">
            <div
              class="recruit-checklist mt-3"
              v-for="(item, index) in [
                'สมัครฟรี ไม่มีค่าใช้จ่าย',
                'รายได้มากกว่า 20,000-40,000 บาท',
                'งานอิสระ ทำงานวันไหนก็ได้',
                'ไม่ต้องหาลูกค้าเอง มีงานทุกวัน',
              ]"
              :key="index"
            >
              <b-img
                src="../assets/images/recruit_checklist.png"
                alt="checklist คุณสมบัติของผู้สมัคร"
              ></b-img>
              <span> {{ item }} </span>
            </div>
          </div>
          <a href="https://line.me/R/ti/p/@bluuupro">
            <b-img
              src="../assets/images/recruit_line.png"
              alt="สมัครคลิ๊กไลน์"
              class="d-block mx-auto recruit-line mt-4 mb-3 px-3"
            ></b-img>
          </a>
        </div>
      </b-container>
    </div>
    <b-row class="">
      <b-col md="6" cols="12">
        <b-img
          src="../assets/images/recruit_2.png"
          alt="รับสมัครแม่บ้าน รายได้สูง 2 ชม. 384 บาท 3 ชม. 559 บาท 4 ชม. 683 บาท 6 ชม. 937 บาท 8 ชม. 1,237 บาท"
          class="d-block mx-auto recruit-data"
        ></b-img>
      </b-col>
      <b-col md="6" cols="12">
        <b-img
          src="../assets/images/recruit_3.png"
          alt="เป็นแม่บ้านกับ bluuu ดีอย่างไร? รายได้สูง 20,000-40,000 บาท/เดือน ไม่มีค่าสมัคร ไม่มีค่าอบรม ฟรีชุดยูนิฟอร์ม อิสระ สามารถรับงานเวลาไหนก็ได้ ไม่ต้องขอวันหยุดกับใคร โบนัส มีโบนัสพิเศษให้ตามผลงาน รับรายได้ทุกสัปดาห์ ได้เงินเร็ว"
          class="d-block mx-auto recruit-data"
        ></b-img>
      </b-col>
    </b-row>
    <b-container>
      <b-row class="">
        <b-col cols="12" class="mt-4">
          <div class="recruit-card">
            <p>คุณสมบัติแม่บ้าน</p>
            <div
              class="card-list"
              v-for="(item, index) in [
                'สัญชาติไทย',
                'สามารถเดินทางได้ในพื้นที่กรุงเทพและปริมณฑล',
                'ไม่มีประวัติอาชญากรรม (ไม่มีข้อยกเว้นรวมถึงการพนัน ฯลฯ)',
                'ใช้โทรศัพท์มือถือระบบ “แอนดรอยด์” ไม่ใช่ “ไอโฟน” (สำหรับติดตั้งแอปพลิเคชัน bluuu)',
              ]"
              :key="index"
            >
              <b-img src="../assets/images/star-icon.png" alt="star icon"></b-img>
              <span> {{ item }} </span>
            </div>
          </div>
        </b-col>
        <b-col cols="12">
          <div class="recruit-card">
            <p>วิธีการสมัครแม่บ้าน</p>
            <div class="d-flex justify-content-center">
              <a href="https://line.me/R/ti/p/@bluuupro" class="card-explain"
                >กรุณาส่งข้อมูลต่อไปนี้ทาง ไลน์: @bluuupro</a
              >
            </div>
            <div
              class="card-list"
              v-for="(item, index) in [
              '1. ชื่อ นามสกุล',
              '2. เบอร์',
              '3. ที่อยู่ปัจจุบัน',
              '4. ประสบการณ์แม่บ้าน (กี่ปี นายจ้างเป็นใคร)',
              '5. ปัจจุบันทำงานกี่วันต่อสัปดาห์ (ถ้ากำลังทำงานอยู่)',
              '6. สามารถรับงานจากเราได้กี่วันต่อสัปดาห์',
              '7. ยืนยันไม่มีประวัติอาชญากรรมใดๆ (เราไม่ยอมรับประเภทใดๆ)',
              ]"
              :key="index"
            >
              <span> {{ item }} </span>
            </div>
            <div style="font-size: 14px">
              คำเตือน:
              บริษัทตรวจสอบประวัติอาชญากรรมของผู้สมัครทุกคนก่อนที่จะรับเข้าทำงานและบริษัทจะไม่รับผู้สมัครที่มีประวัติอาชญากรรมทุกประเภท
              กรุณาแจ้งข้อมูลทุกอย่างตามความจริง
            </div>
            <a href="https://line.me/R/ti/p/@bluuupro">
              <b-img
                src="../assets/images/recruit_line_2.png"
                alt="สมัครคลิ๊กไลน์"
                class="d-block mx-auto recruit-line mt-4 mb-3 px-3"
              ></b-img>
            </a>
          </div>
        </b-col>
      </b-row>
    </b-container>
    <Articles type="maid" />
  </div>
</template>

<script>
import { Vue, Component } from 'vue-property-decorator'
import Articles from '../components/Articles'

@Component({
  components: {
    Articles,
  },
})
export default class RecruitMaid extends Vue {
  isExpand = false
  applyForMaid() {
    window.location =
      this.$i18n.locale === 'en'
        ? 'https://docs.google.com/forms/d/e/1FAIpQLSfJ7XSZBd568USItNTrqxg-AzAVyybYapgT7ddkb0sAk9YjmQ/viewform'
        : ' https://forms.gle/QW8e1vwQ5xeiZC2o7'
  }
}
</script>

<style lang="scss" scoped>
.recruit-hero {
  margin-top: 70px;
  background: linear-gradient(rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3)),
    url('../assets/images/bg_recruitment.jpg') no-repeat;
  background-size: cover;
  background-position: center;
  padding: 30px 0;
  .container {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  div {
    h4,
    h5 {
      line-height: 1.5em;
    }
    h5 {
      font-size: 20px;
    }
  }
}

.flex-sm-column {
  @media (max-width: 576px) {
    flex-direction: column !important;
  }
}

.recruit-title {
  width: 100%;
  max-width: 600px;
  margin: 0 auto;
}

.recruit-line {
  width: 80%;
  max-width: 300px;
  margin: 0 auto;
}

.recruit-data {
  width: 100%;
  margin: 0 auto;
}

.recruit-checklist {
  display: flex;
  align-items: center;
  span {
    font-size: 18px;
    color: #005dff;
    padding: 5px;
    background-color: #e6efff;
    border-radius: 10px;
  }
  img {
    width: 40px;
    height: 40px;
    margin-right: 5px;
  }
}

.recruit-card {
  padding: 10px;
  padding-top: 30px;
  border-radius: 10px;
  border-color: #005dff;
  border-width: 4px;
  border-style: solid;
  position: relative;
  max-width: 900px;
  margin: 20px auto;
  p {
    padding: 8px;
    border-radius: 10px;
    position: absolute;
    top: -24px;
    left: 50%;
    transform: translateX(-50%);
    font-size: 24px;
    font-weight: bold;
    background-color: #005dff;
    color: white;
    white-space: nowrap;
  }
  .card-list {
    display: flex;
    margin-top: 10px;
    margin-bottom: 10px;
    img {
      width: 30px;
      height: 30px;
      margin-right: 5px;
    }
    span {
      font-size: 18px;
      font-weight: normal;
      color: black;
    }
  }

  .card-explain {
    background: #ffed00;
    border-radius: 10px;
    font-weight: bold;
    padding: 10px;
    color: black;
    text-align: center;
    margin: 10px auto;
    font-size: 18px;
    width: auto;
  }
}
</style>
