var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"recruit-hero"},[_c('b-container',[_c('div',[_c('b-img',{staticClass:"d-block mx-auto recruit-title",attrs:{"src":require("../assets/images/recruit_1.png"),"alt":"ด่วน! รับสมัครแม่บ้าน หลายอัตรา"}}),_c('div',{staticClass:"mt-4"},_vm._l(([
              'สมัครฟรี ไม่มีค่าใช้จ่าย',
              'รายได้มากกว่า 20,000-40,000 บาท',
              'งานอิสระ ทำงานวันไหนก็ได้',
              'ไม่ต้องหาลูกค้าเอง มีงานทุกวัน',
            ]),function(item,index){return _c('div',{key:index,staticClass:"recruit-checklist mt-3"},[_c('b-img',{attrs:{"src":require("../assets/images/recruit_checklist.png"),"alt":"checklist คุณสมบัติของผู้สมัคร"}}),_c('span',[_vm._v(" "+_vm._s(item)+" ")])],1)}),0),_c('a',{attrs:{"href":"https://line.me/R/ti/p/@bluuupro"}},[_c('b-img',{staticClass:"d-block mx-auto recruit-line mt-4 mb-3 px-3",attrs:{"src":require("../assets/images/recruit_line.png"),"alt":"สมัครคลิ๊กไลน์"}})],1)],1)])],1),_c('b-row',{},[_c('b-col',{attrs:{"md":"6","cols":"12"}},[_c('b-img',{staticClass:"d-block mx-auto recruit-data",attrs:{"src":require("../assets/images/recruit_2.png"),"alt":"รับสมัครแม่บ้าน รายได้สูง 2 ชม. 384 บาท 3 ชม. 559 บาท 4 ชม. 683 บาท 6 ชม. 937 บาท 8 ชม. 1,237 บาท"}})],1),_c('b-col',{attrs:{"md":"6","cols":"12"}},[_c('b-img',{staticClass:"d-block mx-auto recruit-data",attrs:{"src":require("../assets/images/recruit_3.png"),"alt":"เป็นแม่บ้านกับ bluuu ดีอย่างไร? รายได้สูง 20,000-40,000 บาท/เดือน ไม่มีค่าสมัคร ไม่มีค่าอบรม ฟรีชุดยูนิฟอร์ม อิสระ สามารถรับงานเวลาไหนก็ได้ ไม่ต้องขอวันหยุดกับใคร โบนัส มีโบนัสพิเศษให้ตามผลงาน รับรายได้ทุกสัปดาห์ ได้เงินเร็ว"}})],1)],1),_c('b-container',[_c('b-row',{},[_c('b-col',{staticClass:"mt-4",attrs:{"cols":"12"}},[_c('div',{staticClass:"recruit-card"},[_c('p',[_vm._v("คุณสมบัติแม่บ้าน")]),_vm._l(([
              'สัญชาติไทย',
              'สามารถเดินทางได้ในพื้นที่กรุงเทพและปริมณฑล',
              'ไม่มีประวัติอาชญากรรม (ไม่มีข้อยกเว้นรวมถึงการพนัน ฯลฯ)',
              'ใช้โทรศัพท์มือถือระบบ “แอนดรอยด์” ไม่ใช่ “ไอโฟน” (สำหรับติดตั้งแอปพลิเคชัน bluuu)',
            ]),function(item,index){return _c('div',{key:index,staticClass:"card-list"},[_c('b-img',{attrs:{"src":require("../assets/images/star-icon.png"),"alt":"star icon"}}),_c('span',[_vm._v(" "+_vm._s(item)+" ")])],1)})],2)]),_c('b-col',{attrs:{"cols":"12"}},[_c('div',{staticClass:"recruit-card"},[_c('p',[_vm._v("วิธีการสมัครแม่บ้าน")]),_c('div',{staticClass:"d-flex justify-content-center"},[_c('a',{staticClass:"card-explain",attrs:{"href":"https://line.me/R/ti/p/@bluuupro"}},[_vm._v("กรุณาส่งข้อมูลต่อไปนี้ทาง ไลน์: @bluuupro")])]),_vm._l(([
            '1. ชื่อ นามสกุล',
            '2. เบอร์',
            '3. ที่อยู่ปัจจุบัน',
            '4. ประสบการณ์แม่บ้าน (กี่ปี นายจ้างเป็นใคร)',
            '5. ปัจจุบันทำงานกี่วันต่อสัปดาห์ (ถ้ากำลังทำงานอยู่)',
            '6. สามารถรับงานจากเราได้กี่วันต่อสัปดาห์',
            '7. ยืนยันไม่มีประวัติอาชญากรรมใดๆ (เราไม่ยอมรับประเภทใดๆ)',
            ]),function(item,index){return _c('div',{key:index,staticClass:"card-list"},[_c('span',[_vm._v(" "+_vm._s(item)+" ")])])}),_c('div',{staticStyle:{"font-size":"14px"}},[_vm._v("\n            คำเตือน:\n            บริษัทตรวจสอบประวัติอาชญากรรมของผู้สมัครทุกคนก่อนที่จะรับเข้าทำงานและบริษัทจะไม่รับผู้สมัครที่มีประวัติอาชญากรรมทุกประเภท\n            กรุณาแจ้งข้อมูลทุกอย่างตามความจริง\n          ")]),_c('a',{attrs:{"href":"https://line.me/R/ti/p/@bluuupro"}},[_c('b-img',{staticClass:"d-block mx-auto recruit-line mt-4 mb-3 px-3",attrs:{"src":require("../assets/images/recruit_line_2.png"),"alt":"สมัครคลิ๊กไลน์"}})],1)],2)])],1)],1),_c('Articles',{attrs:{"type":"maid"}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }